<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>

    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import axios from 'axios';
 const default_layout = "default";

export default {
  name: 'App',
  
  data: function () {
      return {

      }
  },
  computed: {
     layout(){
        return  this.$route.meta.layout || default_layout ;
     }
  },

}
</script>

<style>

</style>
