<template>
    <div>
        <section id="page-banner" class="pt-105 pb-130 bg_cover" data-overlay="8" style="background-image: url(/images/page-banner-1.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2>Par Terre</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Par Terre</li>
                                </ol>
                            </nav>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
        <section id="shop-single" class="pt-120 pb-120 gray-bg">
            <div class="container">
                <div class="shop-details">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="shop-left pt-30">
                                <div class="tab-content" id="pills-tabContent">
                                    <div v-for="(image,index) in images" :key="index" :class=" index == currentIndex ? 'active' : ''" class="tab-pane fade show" :id="'pills-image-'+index" role="tabpanel" :aria-labelledby="'pills-image-'+index+'-tab'">
                                        <div class="shop-image"  >
                                            <a :href="DDM+image" class="shop-items"><img :src="DDM+image" alt="Shop"></a>
                                        </div>
                                    </div>
                                    <!-- <div class="tab-pane fade" id="pills-image-2" role="tabpanel" aria-labelledby="pills-image-2-tab">
                                        <div class="shop-image">
                                            <a href="/images/shop-single/ss-2.jpg" class="shop-items"><img src="/images/shop-single/ss-2.jpg" alt="Shop"></a>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-image-3" role="tabpanel" aria-labelledby="pills-image-3-tab">
                                        <div class="shop-image">
                                            <a href="/images/shop-single/ss-3.jpg" class="shop-items"><img src="/images/shop-single/ss-3.jpg" alt="Shop"></a>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-image-4" role="tabpanel" aria-labelledby="pills-image-4-tab">
                                        <div class="shop-image">
                                            <a href="/images/shop-single/ss-1.jpg" class="shop-items"><img src="/images/shop-single/ss-1.jpg" alt="Shop"></a>
                                        </div>
                                    </div> -->
                                </div>
                                <ul class="nav nav-justified  mt-30" id="pills-tab" role="tablist">
                                    <li v-for="(image,index) in images" :key="index"  class="nav-item border">
                                        <a class="active" :id="'pills-image-'+index+'-tab'" @click="showImage(index)" data-toggle="pill" :href="'#pills-image-'+index" role="tab" :aria-controls="'pills-image-'+index" aria-selected="false">
                                            <span class="shop-thum" style="width: 99px;">
                                                <img :src="DDM+image" alt="Thum">
                                            </span>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="shop-right pt-30">
                                <h6>{{shopping.label}} </h6>
                                <span>{{shopping.price}}</span>
                                <p> {{shopping.description}} </p>
                                <!-- <div> -->
                                    <input class="form-control" type="number" v-model="nbLivre" >
                                <!-- </div> -->
                                <div class="add-btn pt-15">
                                    <button type="button" @click="addToCart" class="main-btn">Ajouter au panier</button>
                                </div>
                                {{toasted.state}}
                                <div v-if="toasted.state == 1" class="alert alert-success pt-2" role="alert">
                                    {{toasted.messageSuccess}}
                                    <button type="button" class="close"  data-dismiss="alert" aria-label="close">
                                        <span aria-hidden="">&times;</span>
                                    </button>
                                </div>
                                <div v-if="toasted.state == 2" class="alert alert-warning pt-2" role="alert">
                                    {{toasted.messageWarning}}
                                    <button type="button" class="close"  data-dismiss="alert" aria-label="close">
                                        <span aria-hidden="">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="shop-reviews mt-60">
                                <ul class="nav" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a>
                                    </li>
                                </ul> 
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                        <div class="description-cont pt-40">
                                            <p> {{shopping.description}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="related-item pt-110">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="title pb-10">
                                <h3>Produits connexes</h3>
                            </div>
                        </div>
                    </div>
                    <div v-if="products.length > 0 " class="row justify-content-center">
                        <div v-for="(product,index) in products" :key="index" class="col-lg-3 col-md-6 col-sm-8">
                            <div class="single-publication mt-30 text-center">
                                <div class="image">
                                    <img :src="DDM+product.photo[0]" alt="Publication">
                                    <div class="add-cart">
                                        <ul>
                                            <li><a href="#"><i class="fa fa-shopping-cart"></i></a></li>
                                            <li><a href="#"><i class="fa fa-heart-o"></i></a></li>
                                        </ul> 
                                    </div>
                                </div>
                                <div class="content pt-10">
                                    <h5 class="book-title"><a href="#">{{product.label}} </a></h5>
                                    <div class="price-btn d-flex align-items-center justify-content-between">
                                        <div class="price pt-20">
                                            <span class="normal-price">{{product.price}}</span>
                                        </div>
                                        <div class="button pt-10">
                                            <a :href="'/par-terre-details/'+product.id" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Acheter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { DDM_URL,DDM } from '@/config'
import axios from "axios"
export default {
    data : function() {
        return {
            shopping:{},
            products:[],
            DDM:DDM,
            images:[],
            currentIndex:0,
            nbLivre: "3",
            cart:[],
            toasted:{
                state: 0,
                messageSuccess: "Ajouté au panier",
                messageWarning: "Déja dans le panier"
            }
        }
    },
    mounted: function(){
        this.getShoppingSingle()
    },
    methods:{
        showImage(id){
            this.currentIndex = id
        },
        getShoppingSingle: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'product/'+this.$route.params.idParTerre)
            .then((response) => {console.log('shopping:',response.data)
                app.shopping = response.data
                var test = app.shopping.photo.split(';')
                test.pop()
                app.images= test;
                app.getCart()
                app.chooseType(app.shopping.type_id)
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        chooseType(choose) {
            
                let app = this;
                app.isLoading = true;
                axios.post(DDM_URL+'products-type',{
                    id:choose
                })
                .then((response) => {
                    response.data.forEach(element => {
                        var tof = element.photo.split(';')
                        tof.pop()
                        element.photo = tof
                    });
                    app.products = response.data
                    this.isLoading = false;
                })
                .catch((   ) => {
                    this.isLoading = false;
                });
            
            
        },
        addToCart(){
            let productId = parseInt(this.$route.params.idParTerre);
            let cart_id = [];
            this.cart.forEach(element => {
                cart_id.push(element.product_id);
            });
			if(!cart_id.includes(productId)){
				this.cart.push({product_id : this.shopping.id, label : this.shopping.label , quantity : this.nbLivre, detail :this.shopping.description,price :this.shopping.price});
				const parsed = JSON.stringify(this.cart)
                localStorage.setItem('cartShooping', parsed);
                this.$store.commit('addToCart');
                this.toasted.state = 1
			}else{
                this.cart.forEach(element => {
                    if (element.product_id == productId) {
                        element.quantity = this.nbLivre
                        console.log('element',element)
                    }
                });
                console.log(this.cart)
                const parsed = JSON.stringify(this.cart)
                localStorage.setItem('cartShooping', parsed);
                this.toasted.state = 2
            }
			this.getCart()
		},
        getCart(){
            this.cart = JSON.parse(localStorage.getItem('cartShooping'));
            console.log('cart',this.cart)
        }
    }
}
</script>