import VueRouter from "vue-router";

import Home from './views/home.vue';
import Register from './views/auth/register.vue'
import Login from './views/auth/login.vue'

import ActualiteIndex from './views/actuality/index.vue'
import ActualiteShow from './views/actuality/show.vue'

import SchoolIndex from './views/school/index.vue'
import SchoolShow from './views/school/show.vue'

import ParTerreIndex from './views/shopping/index.vue'
import ParTerreShow from './views/shopping/show.vue'
import Checkout from './views/shopping/checkout.vue'

import AnnalesExamenIndex from './views/annale/examen/index.vue'
import AnnalesExamenShow from './views/annale/examen/show.vue'

import AnnalesSujetIndex from './views/annale/sujet/index.vue'
import AnnalesSujetShow from './views/annale/sujet/show.vue'

import BoutiqueIndex from './views/boutique/index.vue'
import BoutiqueShow from './views/boutique/show.vue'

import PerformanceIndex from './views/school/performance/index.vue'

const routes = [
        {
            path : '/',
            name : 'Home',
            meta : {layout: "default-layout"},
            component: Home
        },
        {
            path : '/register',
            name : 'Register',
            meta : {layout: "default-layout"},
            component: Register
        },
        {
            path : '/login',
            name : 'Login',
            meta : {layout: "default-layout"},
            component: Login
        },
        {
            path : '/actualites',
            name : 'ActualiteIndex',
            meta : {layout: "default-layout"},
            component: ActualiteIndex
        },
        {
            path : '/actualite-details/:idActualite',
            name : 'ActualiteShow',
            meta : {layout: "default-layout"},
            component: ActualiteShow
        },
        {
            path : '/les-etablissements',
            name : 'SchoolIndex',
            meta : {layout: "default-layout"},
            component: SchoolIndex
        },
        {
            path : '/etablissement-details/:idEts',
            name : 'SchoolShow',
            meta : {layout: "default-layout"},
            component: SchoolShow
        },
        {
            path : '/par-terre',
            name : 'ParTerreIndex',
            meta : {layout: "default-layout"},
            component: ParTerreIndex
        },
        {
            path : '/par-terre-details/:idParTerre',
            name : 'ParTerreShow',
            meta : {layout: "default-layout"},
            component: ParTerreShow
        },
        {
            path : '/epreuves-examens',
            name : 'AnnalesExamenIndex',
            meta : {layout: "default-layout"},
            component: AnnalesExamenIndex
        },
        {
            path : '/annale-details/:idAnnale',
            name : 'AnnalesExamenShow',
            meta : {layout: "default-layout"},
            component: AnnalesExamenShow
        },
        {
            path : '/sujets-de-devoir',
            name : 'AnnalesSujetIndex',
            meta : {layout: "default-layout"},
            component: AnnalesSujetIndex
        },
        {
            path : '/sujets-de-devoir/:id',
            name : 'AnnalesSujetShow',
            meta : {layout: "default-layout"},
            component: AnnalesSujetShow
        },
        {
            path : '/checkout',
            name : 'Checkout',
            meta : {layout: "default-layout"},
            component: Checkout
        },
        {
            path : '/boutique-de-vente',
            name : 'BoutiqueIndex',
            meta : {layout: "default-layout"},
            component: BoutiqueIndex
        },
        {
            path : '/boutique-details/:id',
            name : 'BoutiqueShow',
            meta : {layout: "default-layout"},
            component: BoutiqueShow
        },
        {
            path : '/performances',
            name : 'PerformanceIndex',
            meta : {layout: "default-layout"},
            component: PerformanceIndex
        },
    ]

const router = new VueRouter({
    history : true,
    mode: 'history',
    routes,
});
export default router;