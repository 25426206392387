<template>
    <header id="header-part" style="background-color:white !important;">
        <div class="header-top p-0 d-none d-lg-block" >
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="header-contact">
                            <ul>
                                <!-- <li style="color:white;"><i class="fa fa-envelope"></i><a href="#" style="color:white;" >info@yourmail.com</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="header-contact">
                            <ul>
                                <!-- <li style="color:white;"><i class="fa fa-phone"></i><span>+0123-456-5678</span></li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 py-2" style="background-color:#E97E20;">
                        <div class="header-right d-flex justify-content-center">
                            <div v-if="!user" class="login-register" style="color:white;">
                                <ul> 
                                    <li class="ml-0" ><router-link to="/login" style="color:white;">LOGIN</router-link></li>
                                    <li ><router-link to="" style="color:white;">/</router-link></li>
                                    <li ><router-link to="/register" style="color:white;">INSCRIPTION</router-link></li>
                                </ul>
                            </div>
                            <div v-else class="login-register" style="color:white;">
                                <ul> 
                                    <li  >{{user.givens_name}}</li>
                                    <li >{{user.last_name}}</li>
                                    <li style="cursor:pointer"> <i class="fa fa-power-off"></i></li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation">
            <div class="row">
                <span class="col-md-2  col-sm-1 px-4 d-flex align-items-center justify-content-center" style="background-color:#E97E20; color:white; font-family:'abecedary'; font-size:23px">L'école ici et ailleurs...</span>
                <marquee class=" col-md-10 col-sm-10" behavior="scroll" direction="" vspace="" style="background-color:#07294d;color:white" > 
                    <a href="" class="border-right px-2">
                        <p class="text-justify px-2 py-2 d-flex align-items-center" style="color:white">
                            <span class="font-weight-bold text-center">Cote d'Ivoire:</span>
                            DIRECT - En Côte d'Ivoire Laurent Gbagbo vient  d'atterrir à Abidjan
                        </p>
                    </a>
                    <a href="" class="border-right px-2">
                        <p class="text-justify" style="color:white">
                            <span class="font-weight-bold text-center">Cote d'Ivoire :</span>
                            l'epreuve de physique est à reprendre le mercredi de 15H-17H
                        </p>
                    </a>
                    <a href="" class="border-right px-2">
                        <p class="text-justify" style="color:white">
                            <span class="font-weight-bold text-center">Cote d'Ivoire :</span>
                            l'examen du Baccalaureat a debuté depuis ce lundi 28 Juin
                        </p>
                    </a>
                </marquee>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="navbar navbar-expand-lg">
                            <router-link to="/">
                                <img src="/logo.png" alt="Logo" width="120">
                            </router-link>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                            <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                <ul class="navbar-nav ml-auto d-flex align-items-md-center">
                                    <li class="nav-item" @click="headerClass(1)">
                                        <router-link class="px-2 menu-nav py-2"  :class="index == 1 ? 'active': '' "  to="/">Accueil</router-link>
                                        <!-- <ul class="sub-menu">
                                            <li><a class="active" href="index-2.html">Home 01</a></li>
                                            <li><a href="index-3.html">Home 02</a></li>
                                            <li><a href="index-5.html">Home 03</a></li>
                                            <li><a href="index-4.html">Home 04</a></li>
                                        </ul> -->
                                    </li>
                                    <li class="nav-item" @click="headerClass(2)">
                                        <router-link class="px-2 menu-nav py-2" :class="index == 2 ? 'active': '' "  to="/actualites"> <img src="/icones/Actualités.png" class="px-1" height="20" alt=""> Actualités</router-link>
                                        <!-- <ul class="sub-menu">
                                            <li><a class="active" href="index-2.html">Home 01</a></li>
                                            <li><a href="index-3.html">Home 02</a></li>
                                            <li><a href="index-5.html">Home 03</a></li>
                                            <li><a href="index-4.html">Home 04</a></li>
                                        </ul> -->
                                    </li>
                                    <li class="nav-item ">
                                        <a href="https://youtube.com/"  class="px-2 menu-nav py-2" target="_bank" ><img src="/icones/Videos.png" class="px-1" height="20" alt="">Vidéos</a>
                                        <!-- <ul class="sub-menu">
                                            <li><a href="about.html">About Us</a></li>
                                            <li><a href="gallery.html">Gallery</a></li>
                                            <li><a href="policy.html">Privacy Policy</a></li>
                                            <li><a href="faq.html">FAQ</a></li>
                                        </ul> -->
                                    </li>
                                    <li class="nav-item " >
                                        <a href="#"  target="_bank" class="px-2 menu-nav py-2" :class="index == 3 ? 'active': '' "><img src="/icones/Annales.png" class="px-1" height="20" alt="">Annales</a>
                                        <ul class="sub-menu">
                                            <li @click="headerClass(3)" ><router-link to="/sujets-de-devoir" class="menu-nav"  >Sujets de Devoir</router-link></li>
											<li @click="headerClass(3)" ><router-link to="/epreuves-examens"  class="menu-nav">Épreuves d'Examens</router-link></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item" @click="headerClass(4)">
                                        <router-link to="/performances" :class="index == 4 ? 'active': '' "  class="px-2 menu-nav py-2"><img src="/icones/Performances.png" class="px-1" height="20" alt="">Performance</router-link>
                                    </li>
                                    <li class="nav-item" @click="headerClass(5)">
                                        <router-link to="/par-terre" :class="index == 5 ? 'active': '' "  class="px-2 menu-nav py-2"><img src="/icones/Par-terre.png" height="20" alt="">Par terre</router-link>
                                    </li>
                                    <li class="nav-item" @click="headerClass(6)">
                                        <router-link to="/boutique-de-vente" :class="index == 6 ? 'active': '' "  class="px-2 menu-nav py-2"><img src="/icones/Boutique.png" class="px-1" height="20" alt="">Boutique</router-link>
                                        <!-- <ul class="sub-menu">
                                            <li><a href="#">teachers</a></li>
											<li><a href="teachers-2.html">teachers 2</a></li>
                                            <li><a href="teachers-single.html">teacher Single</a></li>
                                        </ul> -->
                                    </li>
                                    <li class="nav-item">
                                        <a href="blog.html" class="px-2 menu-nav py-2"><img src="/icones/On-en-discute.png" class="px-1" height="20" alt="">On en discute</a>
                                    </li>
                                    <li class="nav-item d-sm-block d-md-none">
                                        <a href="#" target="_bank">connexion</a>
                                        <ul class="sub-menu"> 
                                            <li class="ml-0" ><router-link to="/login" style="color:white;">LOGIN</router-link></li>
                                            <li ><router-link to="/register" style="color:white;">INSCRIPTION</router-link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="right-icon text-right">
                                <ul>
                                    <li><a href="/checkout"><i class="fa fa-shopping-bag"></i><span v-if="cart" > {{cart.length}} </span> <span v-else>0</span></a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
  created () {
      this.cart =JSON.parse(this.$store.state.cartShooping)
      this.user =JSON.parse(this.$store.state.user)
      if (this.user) {
          
          console.log('user:',this.user)
      }
      if (this.cart) {
          
          console.log('cart:',this.cart)
      }

  },
    data: function(){
        return{
            user:null,
            cart:null,
            index:1
        }
    },

    methods:{
        logout: function() {
            this.$store.dispatch("logout").then(() => {
                window.location.href = "/login";
            });
        },
        headerClass:function(id){
            this.index = id
        }
    }
}
</script>
<style scoped>


.menu-nav{
    border: Opx solid;
    border-radius: 6px;
}
.menu-nav:hover > img{
    filter: invert(100%);
}
.menu-nav.active > img{
filter: invert(100%);
}
</style>