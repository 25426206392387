<template>
    <div>
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(/images/page-banner-1.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2>{{actualiteSingle.label}}</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item"><a href="#">actualité</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{actualiteSingle.label}}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="event-single" class="pt-120 pb-120 gray-bg">
            <div class="container">
                <div class="events-area">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="events-left">
                                <h3>{{actualiteSingle.label}}</h3>
                                <a href="#"><span><i class="fa fa-calendar"></i> 2 December 2018</span></a>
                                <a href="#"><span><i class="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span></a>
                                <a href="#"><span><i class="fa fa-map-marker"></i> Rc Auditorim</span></a>
                                <img src="/images/event/single-event/se-1.jpg" alt="Event">
                                <p>Accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="events-right">
                                <div class="events-coundwon bg_cover" data-overlay="8" style="background-image: url(/images/event/single-event/coundown.jpg)">
                                    <div data-countdown="2019/08/01"></div>
                                    <div class="events-coundwon-btn pt-30">
                                        <a href="#" class="main-btn">Book Your Seat</a>
                                    </div>
                                </div>
                                <div class="events-address mt-30">
                                    <ul>
                                        <li>
                                            <div class="single-address">
                                                <div class="icon">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                                <div class="cont">
                                                    <h6>Start Time</h6>
                                                    <span>12:00 Am</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="single-address">
                                                <div class="icon">
                                                    <i class="fa fa-bell-slash"></i>
                                                </div>
                                                <div class="cont">
                                                    <h6>Finish Time</h6>
                                                    <span>05:00 Am</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="single-address">
                                                <div class="icon">
                                                    <i class="fa fa-map"></i>
                                                </div>
                                                <div class="cont">
                                                    <h6>Address</h6>
                                                    <span>Street Park ,America</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div id="contact-map" class="mt-25"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { DDM_URL } from '@/config'
import axios from "axios"
export default {
    data : function() {
        return {
            actualiteSingle:{}
        }
    },
    mounted: function(){
        this.getActualitySingle()
    },
    methods:{
        getActualitySingle: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'other-information/'+this.$route.params.idActualite)
            .then((response) => {console.log('levels:',response.data)
                app.actualiteSingle = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
    }
}
</script>