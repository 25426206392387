<template>
    <div>
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item" v-for="(banner,idx) in actualite" :key="idx" :class="{ active: idx==0 }" >
                    <div class="single-slider bg_cover pt-0  pb-0" style="background: url(/ressources/SLIDE-OK.jpg) no-repeat center; background-size:  auto">
                        <div class="fonbleu" style="background: url(/icones/fond-bleu.png) no-repeat left;background-size: 100% 100%">
                            <div class="container pt-100 pb-50" >
                                <div class="row">
                                    <div class="col-xl-7 col-lg-9" >
                                        <a :href="'/actualite-details/'+banner.id" class="slider-cont p-5" target="_bank">
                                            <h2 data-animation="fadeInLeft" data-delay="1s" style="color:white;position:relative; z-index:100;">Lorem ipsum dolor sit amet, <br> consectetur adipisicing elit.</h2>
                                            <p data-animation="fadeInUp" data-delay="1.3s" style="color:white;position:relative; z-index:100;">Donec vitae sapien ut libearo venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt  Sed fringilla mauri amet nibh.</p>
                                            <ul>
                                                <li><a data-animation="fadeInUp" data-delay="1.6s" class="main-btn" href="https://www.youtube.com/">Tous nos cours</a></li>
                                                <li><a data-animation="fadeInUp" data-delay="1.9s" class="main-btn" href="/epreuves-examens" style="border:none; background-color:white;">Nos corrigés</a></li>
                                            </ul>
                                        </a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <!-- <img class="d-block w-100" src="images/slider/s-1.jpg" alt="First slide"> -->
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon apply-color-1" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon apply-color-1" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <section id="about-part" style="padding-bottom:100px;
        background:url('/icones/Etablissement.jpg') no-repeat center ; background-size: 100% auto; position:relative; top:-40px;" class="">
            <div class="container" >
                <div class="row">
                    <div class="col-lg-8 ">
                        <div class="section-title pt-40 col-md-8 mt-20">
                           <router-link to="/les-etablissements"> <h5 class="text-uppercase"> Je Recherche un établissement </h5> </router-link>
                            <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. </h3>
                        </div>
                        <div class="about-cont col-md-8">
                            <p>Lorem ipsum gravida nibh vel velit auctor aliquetn sollicitudirem quibibendum auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet . Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt  mauris. <br> <br> auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet . </p>
                            <a href="/les-etablissements" class="main-btn mt-20" style="color:white">Recherche</a>
                        </div>
                    </div>
                    <div class="col-lg-4   pt-70">
                        <div class="about-event pl-3 pr-1 pt-1 pb-1 mt-1" style="opacity:0.8 !important">
                            <ul>
                                <li>
                                    <div class="single-event p-1" style="">
                                        <a href="events-single.html"><h4>RESULTAT DES EXAMENS</h4></a>
                                        <span class="pt-0 m-0" style="color: black"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quos rerum necessitatibus</span> <br>
                                        <span class="pt-0 m-0 border-bottom"><a href="#" style="color:black">Découvrir <i class="fa fa-caret-right"></i></a></span>
                                    </div>
                                </li>
                                <li>
                                    <div class="single-event p-1">
                                        <a href="events-single.html"><h4>STATISTIQUES DES EXAMENS</h4></a>
                                        <span class="pt-0 m-0" style="color: black"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quos rerum necessitatibus</span> <br>
                                        <span class="pt-0 m-0 border-bottom"><a  href="#" style="color:black">Découvrir <i class="fa fa-caret-right"></i></a></span>
                                    </div>
                                </li>
                                <li>    
                                    <div class="single-event p-1">
                                        <a href="events-single.html"><h4>EVENEMENT</h4></a>
                                        <span class="pt-0 m-0" style="color: black"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quos rerum necessitatibus</span> <br>
                                        <span class="pt-0 m-0 border-bottom"><a href="#" style="color:black">Découvrir <i class="fa fa-caret-right"></i></a></span>
                                    </div>
                                </li>
                            </ul> 
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <section id="apply-aprt" class="pb-0" >
            <div class="">
                <div class="apply">
                    <div class="row no-gutters d-flex justify-content-center">
                        <div class="col-md-1"></div>
                        <div class="col-lg-5">
                            <div class="apply-cont apply-color-1 p-3 ">
                                <h3>Je prépare mon BAC</h3>
                                <p>Gravida nibh vel velit auctor aliquetn sollicitudirem sem quibibendum auci elit cons equat ipsutis sem nibh id elituis sed odio sit amet nibh vulputate cursus equat ipsutis.</p>
                                <router-link class="main-btn" to="/annale-details/2" style="background-color: white; border:none">Decouvrir</router-link>
                            </div>
                        </div>
                        <div class="col-lg-5" >
                            <div class="apply-cont apply-color-2 p-3" style="background-color:#587295;">
                                <h3>Je prépare mon Brevet</h3>
                                <p>Gravida nibh vel velit auctor aliquetn sollicitudirem sem quibibendum auci elit cons equat ipsutis sem nibh id elituis sed odio sit amet nibh vulputate cursus equat ipsutis.</p>
                                <router-link class="main-btn" to="/annale-details/1" style="background-color: white; border:none">Decouvrir</router-link>
                            </div>
                        </div> 
                        <div class="col-lg-1 ">
                            <img class="d-none d-md-block" src="images/icon/diplome.png" style="position:relative;" alt="About">
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <div style="position:relative; top:-50px; left: -30px;">
            <img src="/ressources/icons/Icon-1.png" width="7%" alt="">
        </div>
        <section id="course-part" class="pt-0 pb-120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title pb-45 text-center">
                            <p class="h5">VIDEOS</p>    
                            <div class="border-bottom mx-auto" style="width:6% ;border: 2px solid; color:#E97E20;"> </div>
                            <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. </h3>
                            <p style="padding-right: 20%;padding-left: 20%;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem mollitia iste, similique consectetur quos id ipsa dolore, est reprehenderit commodi explicabo repudiandae quasi repellendus culpa sunt quo ipsum quaerat molestiae.</p>
                            <div class="float-right" style="position:relative; top:30px;" >
                                <img src="/ressources/icons/Icon-1.png" width="20%" alt="">
                            </div>
                        </div> 
                        
                    </div>
                </div>
                <div class="row course-slide mt-30">
                    <div class="col-lg-4 p-2">
                        <div class="single-course">
                            <div class="thum">
                                <div class="image">
                                    <img src="/videos/Cours-1.png" alt="Course">
                                </div>
                            </div>
                            <div class="cont" style="background-color:#587295; color:white;">
                                <div >
                                    1ere
                                </div>
                                <div class="col-md-5 border-bottom"></div>
                                <h4 class="pb-2" style="color:white;">Learn basic javascript from start for beginner</h4>
                                <p style="color:white;">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero, nemo dicta commodi tenetur laborum magnam magni</p>
                                <div class="course-teacher d-flex justify-content-between">
                                    <div>
                                        <a href="https://youtube.com/" target="_bank"><h6 style="color:white">Accéder au cours <i class="fa fa-angle-double-right"></i></h6></a>
                                    </div>
                                    <div >
                                        <ul>
                                            <li><a href="#" style="color:white"><i class="fa fa-users" ></i><span style="color:white">31</span></a></li>
                                            <li><a href="#" style="color:white"><i class="fa fa-heart" ></i><span style="color:white">10</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 p-2">
                        <div class="single-course">
                            <div class="thum">
                                <div class="image">
                                    <img src="/videos/Cours-2.png" alt="Course">
                                </div>
                            </div>
                            <div class="cont" style="background-color:#587295; color:white;">
                                <div >
                                    classe de 1ere
                                </div>
                                <div class="col-md-5 border-bottom"></div>
                                <h4 class="pb-2" style="color:white;">Learn basic javascript from start for beginner</h4>
                                <p style="color:white;">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero, nemo dicta commodi tenetur laborum magnam magni</p>
                                <div class="course-teacher d-flex justify-content-between">
                                    <div>
                                        <a href="https://youtube.com/"  target="_bank"><h6 style="color:white">Accéder au cours <i class="fa fa-angle-double-right"></i></h6></a>
                                    </div>
                                    <div >
                                        <ul>
                                            <li><a href="#" style="color:white"><i class="fa fa-users" ></i><span style="color:white">31</span></a></li>
                                            <li><a href="#" style="color:white"><i class="fa fa-heart" ></i><span style="color:white">10</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 p-2">
                        <div class="single-course">
                            <div class="thum">
                                <div class="image">
                                    <img src="/videos/Cours-3.png" alt="Course">
                                </div>
                            </div>
                            <div class="cont" style="background-color:#587295; color:white;">
                                <div >
                                    classe de 1ere
                                </div>
                                <div class="col-md-5 border-bottom"></div>
                                <h4 class="pb-2" style="color:white;">Learn basic javascript from start for beginner</h4>
                                <p style="color:white;">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero, nemo dicta commodi tenetur laborum magnam magni</p>
                                <div class="course-teacher d-flex justify-content-between">
                                    <div>
                                        <a href="https://youtube.com/" target="_bank" ><h6 style="color:white">Accéder au cours <i class="fa fa-angle-double-right"></i></h6></a>
                                    </div>
                                    <div >
                                        <ul>
                                            <li><a href="#" style="color:white"><i class="fa fa-users" ></i><span style="color:white">31</span></a></li>
                                            <li><a href="#" style="color:white"><i class="fa fa-heart" ></i><span style="color:white">10</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="video-feature" class="bg_cover pt-30 pb-30" style="background-image: url(images/Statistiques.jpg)">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 order-last order-lg-first">
                        <div class="video text-lg-left text-center pt-50">
                            <!-- <a class="Video-popup" href="https://www.youtube.com/watch?v=bRRtdzJH1oE"><i class="fa fa-play"></i></a> -->
                        </div> 
                    </div>
                    <div class="col-md-5  offset-lg-1 order-first order-lg-last" style="margin-left: 50px;" >
                        <div class="feature " >
                            <div class="feature-title">
                                <h3 style="color:black;" class="text-uppercase">la communauté </h3>
                            </div>
                            <ul>
                                <li>
                                    <div class="single-feature">
                                        <div class="icon p-0">
                                            <img src="/ressources/Icons/Professeurs-enregistrés.png" alt="icon" style="color:white">
                                        </div>
                                        <div class="cont pl-2" >
                                            <h6 style="color:black;">Nombre de professeurs enregistré</h6>
                                            <h1 style="color:black;" class="font-weight-bold">+50</h1>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="single-feature">
                                        <div class="icon p-0">
                                            <img src="/ressources/Icons/Sujets-enregistrés.png" alt="icon" style="color:white">
                                        </div>
                                        <div class="cont pl-2">
                                            <h6 style="color:black;">Nombre de sujet traités</h6>
                                            <h1 style="color:black;" class="font-weight-bold">+90</h1>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="single-feature">
                                        <div class="icon p-0">
                                            <img src="/ressources/Icons/Ecoles.png" alt="icon" style="color:white">
                                        </div>
                                        <div class="cont pl-2">
                                            <h6 style="color:black;">Nombre d'ecoles referencés</h6>
                                            <h1 style="color:black;" class="font-weight-bold">+650</h1>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="feature-bg"></div>
        </section>
        <section id="publication-part" class="pt-70 pb-80 gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 d-flex align-items-center" style="position :relative; left:-110px">
                        <img src="/ressources/icons/Icon-3.png" width="40%" alt="">
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-7 d-flex text-center">
                        <div class="section-title pb-60">
                            <p class="h5">Par terre</p>
                            <div class="border-bottom mx-auto" style="width:6% ;border: 2px solid; color:#E97E20;"> </div>
                            <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. </h3>
                            <p style="padding-right: 20%;padding-left: 20%;">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere dignissimos provident repellendus perspiciatis officia fuga repellat necessitatibus, praesentium ipsa veniam cumque nobis modi? Incidunt, accusamus consectetur. Quia ducimus qui nulla?</p>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex align-items-center">
                        <img src="/ressources/icons/Icon.png" width="40%" alt="">
                    </div>
                </div>
                <div class="row justify-content-center" v-if="products.length">
                    <div class="col-lg-3 col-md-6 col-sm-8" v-for="(product,index) in products.slice(0, 4)" :key="index">
                        <div class="single-publication mt-20 text-center">
                            <div class="image">
                                <img :src="DDM+product.photo[0]" alt="Publication">
                                <div class="add-cart">
                                    <ul>
                                        <li><a href="#"><i class="fa fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fa fa-heart-o"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="content pt-10">
                                
                                <h5 class="book-title"><a href="#">{{product.label}} </a></h5>
                                <p class="writer-name"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla, quo voluptatibus ut</p>
                                <div class="price-btn d-flex align-items-center justify-content-between">
                                    <div class="price pt-20">
                                        <span class="normal-price">{{product.price}} Fcfa</span>
                                    </div>
                                    <div class="button pt-10">
                                        <router-link :to="'/par-terre-details/'+product.id" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Acheter</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="col-lg-12 col-md-12 col-sm-5">
                    <div style="position:relative; left:300px">
                        <img class="float-left" src="/ressources/icons/Icon-3.png" width="5%" alt="">
                    </div>
                    <div class="products-btn text-center pt-60">
                        <router-link to="/par-terre" class="main-btn" style="color:white">Voir plus</router-link>
                    </div> 
                </div>
            </div>
            <div class="float-right" style="position:relative; top:45px">
                <img class="float-right" src="/images/icon/online-course.png" width="20%" alt="">
            </div>
        </section>
        <section id="" class="bg_cover pt-70 pb-1" data-overlay="8" style="background-image: url(images/bg-2.jpg)">
            <div class="container" style="position:relative; z-index:2;">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="section-title pb-40">
                            <h4 style="color:white">ANNALES</h4>
                            <div class="border-bottom" style="width:6% ;border: 2px solid; color:#E97E20;"> </div><br>
                            <h6 style="color:white"> Lorem ipsum dolor sit amet</h6>
                            <p style="color:white">consectetur adipisicing elit. Fugiat aliquam consectetur et ducimus saepe. Aliquid quidem cum, repellendus doloremque doloribus culpa</p>   
                        </div>
                    </div>
                </div>
                <div class="row  mt-0">
                    <div class="row" >
                        <div class="col-md-6" >
                            <div class="single-publication mt-30" style="background-color:transparent;">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="image">
                                            <img src="/annales/Annale-4.png" alt="Publication">
                                            <div class="add-cart">
                                                <ul>
                                                    <li><a href="/sujets-de-devoir"><i class="fa fa-shopping-cart"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="content pt-20">
                                            <h5 class="book-title"><a href="#" style="color:white">DEVOIRS DE LYCEE </a></h5>
                                            <div class="description" >
                                                <p style="color:white">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                            </div>
                                            <div class="price-btn d-flex align-items-center justify-content-between">
                                                <div class="button pt-10">
                                                    <router-link to="/sujets-de-devoir" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Consulter</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" >
                            <div class="single-publication mt-30" style="background-color:transparent;">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="image">
                                            <img src="/annales/Annale-3.png" alt="Publication">
                                            <div class="add-cart">
                                                <ul>
                                                    <li><a href="/epreuves-examens"><i class="fa fa-shopping-cart"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="content pt-20">
                                            <h5 class="book-title"><a href="#" style="color:white">EXAMENS DE BACCALAUREAT </a></h5>
                                            <div class="description">
                                                <p style="color:white">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                            </div>
                                            <div class="price-btn d-flex align-items-center justify-content-between">
                                                <div class="button pt-10">
                                                    <router-link to="/epreuves-examens" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Consulter</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" >
                            <div class="single-publication mt-30" style="background-color:transparent;">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="image">
                                            <img src="/annales/Annales-1.png" alt="Publication">
                                            <div class="add-cart">
                                                <ul>
                                                    <li><a href="/sujets-de-devoir"><i class="fa fa-shopping-cart"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="content pt-20">
                                            <h5 class="book-title"><a href="#" style="color:white">DEVOIRS DE COLLEGE </a></h5>
                                            <div class="description">
                                                <p style="color:white">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                            </div>
                                            <div class="price-btn d-flex align-items-center justify-content-between">
                                                
                                                <div class="button pt-10">
                                                    <router-link to="/sujets-de-devoir" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Consulter</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" >
                            <div class="single-publication mt-30" style="background-color:transparent;">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="image">
                                            <img src="/annales/Annale-4.png" alt="Publication">
                                            <div class="add-cart">
                                                <ul>
                                                    <li><a href="/epreuves-examens"><i class="fa fa-shopping-cart"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="content pt-20">
                                            <h5 class="book-title"><a href="#" style="color:white">EXAMENS DE BREVET </a></h5>
                                            <div class="description">
                                                <p style="color:white">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                            </div>
                                            <div class="price-btn d-flex align-items-center justify-content-between">
                                                <div class="button pt-10">
                                                    <router-link to="/epreuves-examens" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Consulter</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="" style="position:relative; top:70px; left:99%">
                        <img src="images/icon/book.png" width="20%"  alt="">
                    </div>
                </div> 
            </div>
        </section>
        <section class="admission-row pt-120">
            
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="section-title pb-45">
                            <h5>Boutique</h5>
                            <h3>Featured courses </h3>
                        </div> <!-- section title -->
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="admission-card mt-20 d-flex justify-content-center align-items-center" 
                            style="background-image: url(/ressources/Fournitures-scolaires.jpg) ;" data-overlay="7">
                            <a href="/boutique-de-vente" >
                                <h3 style="color:white; position:relative; z-index:2;"> {{marketTypes[1].label}}  </h3>
                                <div class="border-bottom mx-auto" style="width:30% ;border: 2px solid #E97E20 !important; color:#E97E20; position:relative; z-index:2;"> </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="admission-card mt-20 d-flex justify-content-center align-items-center" 
                             style="background: url(/ressources/Tenues-scolaires.jpg) ;" data-overlay="7">
                            <a href="/boutique-de-vente" >
                                <h3 style="color:white; position:relative; z-index:2;">{{marketTypes[0].label}}</h3>
                                <div class="border-bottom mx-auto" style="width:30% ;border: 2px solid #E97E20 !important; color:#E97E20; position:relative; z-index:2;"> </div>
                            </a>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="admission-card mt-20 d-flex justify-content-center align-items-center" 
                             style="background: url(/ressources/Accessoires-divers.jpg) ;"  data-overlay="7">
                            <a href="/boutique-de-vente" >
                                <h3 style="color:white; position:relative; z-index:2;">{{marketTypes[2].label}}</h3>
                                <div class="border-bottom mx-auto" style="width:30% ;border: 2px solid #E97E20 !important; color:#E97E20; position:relative; z-index:2;"> </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { DDM_URL,DDM } from '@/config'
import axios from "axios"
export default {
    data : function() {
        return {
            courses:[],
            products:[],
            actualite:[],
            examens:[],
            sujets:[],
            marketTypes:[],
            DDM:DDM
        }
    },
    created: function(){
        this.getCourses()
        this.getShopping()
        this.getActualite()
        this.getExamens()
        this.getBoutiqueType()
    },
    methods:{
        getCourses: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'courses')
            .then((response) => {console.log('levels:',response.data)
                app.courses = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        getShopping: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'products')
            .then((response) => {
                    response.data.forEach(element => {
                        var tof = element.photo.split(';')
                        tof.pop()
                        element.photo = tof
                    });
                app.products = response.data
                console.log('shopping:',response.data)
                this.isLoading = false;
            })
            .catch(( error  ) => {
                console.log(error)
                this.isLoading = false;
            });
        },
        getActualite: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'other-informations')
            .then((response) => {console.log('actualite:',response.data)
                
                    app.actualite = response.data
                    console.log(app.actualite)
                
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        getExamens : function () { 
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'examens')
            .then((response) => {
                console.log('schools:',response.data)
                app.examens = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        getBoutiqueType: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'short-market-types')
            .then((response) => {console.log('type:',response.data)
                app.marketTypes = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
    }
}
</script>   

<style scoped>
div.icon{
  padding: 5px !important;
  padding-right: 0px;
  width: 100px;
  height: 100px;
}
.plan{
    background-color: #07294D;
}
.feature-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
}
.about-event ul li .single-event span i {
    color: #07294D;
    margin-right: 3px;
}
@media screen and (max-width: 600px) {
  .feature-bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.8;
    }

    .admission-card{
        text-align: center;
    }

    .fonbleu{
        background-image: url('/icones/blueform.png') !important;
    }
    #about-part{
        background:url('/icones/EtablissementShort.png') !important;
        background-position: center;
        background-size: 100% auto !important;
        background-repeat: no-repeat !important;
    }
}
.swipe{
    overflow:auto;
    white-space:nowrap;
}
</style>