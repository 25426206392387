<template>
    <div>
        <section id="page-banner" class="pt-105 pb-130 bg_cover" data-overlay="3" style="background-image: url(/images/actualites.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2 class="text-uppercase">Actualités</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active text-uppercase" aria-current="page">Actualités</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="blog-page" class="pt-90 pb-120 gray-bg">
            <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="single-blog mt-30" v-for="(actualite,index) in actualites" :key="index">
                        <div class="blog-thum">
                            <img :src="DDM+actualite.photo" alt="Blog">
                        </div>
                        <div class="blog-cont">
                            <router-link :to="'/actualite-details/'+actualite.id" class="text-capitalize"><h3>{{actualite.label}}</h3></router-link>
                            <ul>
                                <li><a href="#"><i class="fa fa-calendar"></i>25 Dec 2018</a></li>
                                <li><a href="#"><i class="fa fa-user"></i>Mark anthem</a></li>
                                <li><a href="#"><i class="fa fa-tags"></i>Education</a></li>
                            </ul>
                            <div v-html="actualite.value">

                            </div>
                            <p>Lorem ipsum gravida nibh vel velit auctor aliquetn sollicitudirem quibibendum auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus .</p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card-footer text-center border-0  pb-0 pt-3" style="background-color:transparent;">
                            <jw-pagination :pageSize="10" :items="exampleItems" @changePage="onChangePage" :labels="customLabels"></jw-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sidebar">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="sidebar-search mt-30">
                                    <form action="#">
                                        <input type="text" placeholder="Rechercher un article">
                                        <button type="button"><i class="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="sidebar-post mt-30">
                                    <h4 class="text-center" style="color:#E97E20;">TOP ARTICLES</h4>
                                    <div class="border-bottom mx-auto" style="width:30% ;border: 2px solid; color:#E97E20;"> </div> <br>
                                    <ul>
                                        <li v-for="(actualite,index) in actualites.slice(0,3)" :key="index">
                                            <a :href="'/actualite-details/'+actualite.id">
                                                <div class="single-post">
                                                    <div class="thum p-0 col-4">
                                                        <img :src="DDM+actualite.photo" alt="Blog">
                                                    </div>
                                                    <div class="cont col-8 float-right">
                                                        <h6>Lorem ipsum</h6>
                                                        <span>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates</span>
                                                    </div>
                                                </div> 
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};

import { DDM_URL,DDM } from '@/config'
import axios from "axios"
export default {
    data : function() {
        return {
            actualites:[],
            exampleItems:[],
            pageOfItems: [],
            customLabels,
            DDM:DDM
        }
    },
    mounted: function(){
        this.getActuality()
    },
    methods:{
        onChangePage(pageOfItems) {
            // update page of items
            this.actualites = pageOfItems;
        },
        getActuality: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'other-informations')
            .then((response) => {console.log('actuality:',response.data)
                app.actualites = response.data
                app.exampleItems = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
    }
}
</script>