<template>
    <div>
      <section id="page-banner" class="pt-105 pb-130 bg_cover" data-overlay="8" style="background-image: url(/images/page-banner-1.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2>Panier</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Panier</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
        <section id="shop-page" class="pt-120 pb-120 gray-bg">
            <div class="container">
                <div class="row" v-if="cart">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Libellé</th>
                        <th scope="col">Quantité</th>
                        <th scope="col">Prix</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(elt,index) in cart" :key="index">
                        <th scope="row"> {{index+1}} </th>
                        <td> {{elt.label}} </td>
                        <td>{{elt.quantity}}</td>
                        <td>{{elt.price}}</td>
                      </tr>
                      <tr>
                        <th colspan="3" scope="col">Total</th>
                        <th scope="col"> {{total}} </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <div class="alert alert-danger text-center">
                      Votre Panier est vide
                  </div>
                </div>
            </div> 
        </section>
    </div>
</template>
<script>
export default {
  data () {
    return {
        cart:null,
        total:null
    }
  },
  created () {
      this.cart =JSON.parse(this.$store.state.cartShooping)
      let count=0
      this.cart.forEach(element => {
        count = count+ element.price 
      });
      this.total = count
  },
  methods: {
  },
    
}
</script>