<template>
    <div>
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(/images/page-banner-1.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2>Etablissements scolaire</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">etablissements scolaire</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="event-page" class="pt-90 pb-120 gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="courses-top-search">
                            <ul class="nav float-left" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="active" id="courses-grid-tab" data-toggle="tab" href="#courses-grid" role="tab" aria-controls="courses-grid" aria-selected="true"><i class="fa fa-th-large"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a id="courses-list-tab" data-toggle="tab" href="#courses-list" role="tab" aria-controls="courses-list" aria-selected="false"><i class="fa fa-th-list"></i></a>
                                </li>
                                <li class="nav-item">Showing 4 0f 24 Results</li>
                            </ul>
                            
                            <div class="courses-search float-right">
                                <form action="#">
                                    <div>
                                        <input type="text" placeholder="Search">
                                        <button type="button"><i class="fa fa-search"></i></button>
                                    </div>
                                    <div >
                                        <label class="col-md-2" for=""> Pays</label>
                                       
                                            <select class="form-control " v-model="countryId" @change="selectCountry(countryId)" name="" id="">
                                                <option v-for="(country,index) in countries" :key="index"  :value="country.id"> {{country.name}} </option>
                                            </select>
                                       
                                    </div>
                                    <div >
                                        <label class="col-md-2" for="">Ville</label>
                                        <select class="form-control" v-model="cityId" @change="selectCity(cityId)" name="" id="">
                                            <option v-for="(city,index) in cities" :key="index"  :value="city.id"> {{city.name}} </option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 pt-4">
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=16ZFBUysRYIf-clTW2i2Ace-mfu4apQFA" width="100%" height="480"></iframe>
                </div>
                <div class="row">
                    <div class="col-lg-6" v-for="(school,index) in schooles" :key="index">
                        <div class="single-event-list mt-30">
                            <div class="event-thum">
                                <img src="https://via.placeholder.com/195x160?text=devoirdemaison.com" alt="Event">
                            </div>
                            <div class="event-cont">
                                <span><i class="fa fa-calendar"></i>{{school.code}}</span>
                                    <router-link :to="'/etablissement-details/'+school.id"><h4>{{school.name}}</h4></router-link>
                                    <span ><i class="fa fa-map-marker"></i> {{school.location}}</span>
                                    <!-- <span v-else><i class="fa fa-map-marker"></i> {{school.type}}</span> -->
                                    <p v-html="school.value"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card-footer text-center border-0  pb-0 pt-3" style="background-color:transparent;">
                            <jw-pagination :pageSize="10" :items="exampleItems" @changePage="onChangePage" :labels="customLabels"></jw-pagination>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
    </div>
</template>
<style scoped>
#map {
  height: 100px;
  overflow: visible;
}
.mapouter
{position:relative;
text-align:right;
height:500px;
width:733px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:500px;width:733px;
}
</style>
<script>

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};


import { DDM_URL } from '@/config'
import axios from "axios"
export default {

    data : function() {
        return {
            schooles:[],
            cities:[],
            countries:[],
            countryId:null,
            cityId: null,    
            exampleItems:[],
            pageOfItems: [],
            customLabels,
        }
    },
    mounted: function(){
        
        this.getCountries()
    },
    methods:{
        onChangePage(pageOfItems) {
            // update page of items
            this.schooles = pageOfItems;
        },
        getSchooles() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'schools')
            .then((response) => {console.log('schools:',response.data)
                app.schooles = response.data
                app.exampleItems = response.data
                // app.exampleItems = [...response.data].map((school,index) => ({ id: school.id, label: school.label,level:school.level,price:school.price }));
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        // getCity() {
        //     let app = this;
        //     app.isLoading = true;
        //     axios.get(DDM_URL+'cities')
        //     .then((response) => {
        //         console.log('cities:',response.data)
        //         app.cities = response.data
        //         this.isLoading = false;
        //     })
        //     .catch((   ) => {
        //         this.isLoading = false;
        //     });
        // },
        getCountries() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'countries')
            .then((response) => {
                console.log('countries:',response.data)
                app.countries = response.data
                app.getSchooles()
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        selectCountry : function (id){
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'country-city/'+id)
            .then((response) => {console.log('countries:',response.data)
                app.cities = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        selectCity : function (id){
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'schooles-city/'+id)
            .then((response) => {console.log('countries:',response.data)
                app.schooles = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        }
    }
}
</script>