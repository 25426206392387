<template>
    <div>
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(/images/page-banner-1.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2>{{actualiteSingle.name}}</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item"><a href="#">Etablissement</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{actualiteSingle.name}}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="courses-single" class="pt-90 pb-120 gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="courses-single-left mt-30">
                            <div class="title">
                                <h3> {{actualiteSingle.name}} </h3>
                            </div>
                            <div class="courses-single-image pt-50">
                                <img src="/images/course/cu-1.jpg" alt="Courses">
                            </div> 
                            
                            <div class="courses-tab mt-30">
                                <ul class="nav nav-justified" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Informations</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="curriculum-tab" data-toggle="tab" href="#curriculum" role="tab" aria-controls="curriculum" aria-selected="false">Sujet d'examen</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="instructor-tab" data-toggle="tab" href="#instructor" role="tab" aria-controls="instructor" aria-selected="false">Performances</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="reviews-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" aria-selected="false">Classe</a>
                                    </li>
                                </ul>
                                
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                        <div class="overview-description">
                                            <div v-for="(info,index) in actualiteSingle.school_informations" :key="index" class="single-description pt-40">
                                                <h6> {{info.label}} </h6>
                                                <p v-html="info.value">  </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                                        <div class="curriculum-cont">
                                            <div class="title">
                                                <h6>Learn basic javascript Lecture Started</h6>
                                            </div>
                                            <div class="accordion" id="accordionExample">
                                                <div class="card" v-for="(sujet,index) in actualiteSingle.examens" :key="index+'sujet'">
                                                    <div class="card-header" :id="sujet.subject">
                                                        <a href="#" data-toggle="collapse" @click="showCollapse(index)" :data-target="'#'+index+'sujet'" aria-expanded="true" :aria-controls="index+'sujet'">
                                                            <ul>
                                                                <li><i class="fa fa-file-o"></i></li>
                                                                <li><span class="lecture">{{index+1}}</span></li>
                                                                <li><span class="head">{{sujet.subject}}</span></li>
                                                                <li><span class="time d-none d-md-block"><i class="fa fa-clock-o"></i> <span> 00.30.00</span></span></li>
                                                            </ul>
                                                        </a>
                                                    </div>
                                                    <div :id="index+'sujet'" :class=" isActive == index ? 'show' : '' " class="collapse" :aria-labelledby="sujet.subject" data-parent="#accordionExample">
                                                        <div class="card-body">
                                                            <a :href="DDM+sujet.document" > 
                                                                <a :href="DDM+sujet.document" download="mathematique" target="_bank">{{sujet.year}} </a>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="instructor" role="tabpanel" aria-labelledby="instructor-tab">
                                        <div class="instructor-cont">
                                            <div class="instructor-author">
                                                <div class="author-thum">
                                                    <img src="/images/instructor/i-1.jpg" alt="Instructor">
                                                </div>
                                                <div class="author-name">
                                                    <a href="#"><h5>John Doe</h5></a>
                                                    <span>Senior WordPress Developer</span>
                                                    <ul class="social">
                                                        <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="instructor-description pt-25">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus fuga ratione molestiae unde provident quibusdam sunt, doloremque. Error omnis mollitia, ex dolor sequi. Et, quibusdam excepturi. Animi assumenda, consequuntur dolorum odio sit inventore aliquid, optio fugiat alias. Veritatis minima, dicta quam repudiandae repellat non sit, distinctio, impedit, expedita tempora numquam?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                        <div class="reviews-cont">
                                            <ul>
                                                <li>
                                                    <div class="single-reviews">
                                                        <div class="reviews-description pt-20">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Niveau</th>
                                                                    <th scope="col">Nombre de classe</th>
                                                                    <th scope="col">Scolarité</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(level,index) in actualiteSingle.school_level" :key="index">
                                                                        <th scope="row">{{index + 1}}</th>
                                                                        <td>{{level.label}}</td>
                                                                        <td>{{level.pivot.class_nb}}</td>
                                                                        <td>{{level.pivot.tuition}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="course-features mt-30">
                                    <ul>
                                        <li><i class="fa fa-barcode "></i>Code : <span>{{actualiteSingle.code}}</span></li>
                                        <li><i class="fa fa-calendar"></i>date de creation : <span>{{actualiteSingle.date_creation}}</span></li>
                                        <li><i class="fa fa-envelope-o"></i>Email :  <span>{{actualiteSingle.email}}</span></li>
                                        <li><i class="fa fa-phone"></i>Contacts :  <span>{{actualiteSingle.phone_numbers}}</span></li>
                                        <li><i class="fa fa-reorder"></i>Type : <span>{{actualiteSingle.type}}</span></li>
                                        <li><i class="fa fa-globe"></i>Site Web : <span>{{actualiteSingle.website}}</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="You-makelike mt-30">
                                    <div class="single-makelike mt-20">
                                        <div class="image">
                                            <img src="/images/your-make/y-1.jpg" alt="Image">
                                        </div>
                                        <div class="cont">
                                            <a href="#"><h4>Nombre de classe</h4></a>
                                            <ul>
                                                <li>{{actualiteSingle.school_level.length}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="single-makelike mt-20">
                                        <div class="image">
                                            <img src="/images/your-make/y-1.jpg" alt="Image">
                                        </div>
                                        <div class="cont">
                                            <a href="#"><h4>Nombre de sujet </h4></a>
                                            <ul>
                                                <li>{{actualiteSingle.examens.length}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { DDM_URL, DDM } from '@/config'
import axios from "axios"
export default {
    data : function() {
        return {
            actualiteSingle:{},
            DDM:DDM,
            isActive:null
        }
    },
    mounted: function(){
        this.getActualitySingle()
    },
    methods:{
        showCollapse (index) { 
            this.isActive = index
        },
        getActualitySingle: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'school/'+this.$route.params.idEts)
            .then((response) => {console.log('school single:',response.data)
                app.actualiteSingle = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
    }
}
</script>
