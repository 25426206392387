import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from '@/router'
import store from "./store";
import JwPagination from 'jw-vue-pagination';


Vue.component('jw-pagination', JwPagination);

import Default from '@/layouts/default.vue'

Vue.component('default-layout', Default)

Vue.use(VueRouter)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
