import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import { DDM_URL} from "@/config";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoading: 0,
        cart: 0,
        cartShooping:localStorage.getItem("cartShooping") || [],
        client : localStorage.getItem("client") || '',
        isAdmin: localStorage.getItem("admin") || '',
        token: localStorage.getItem("token") || '',
        user: localStorage.getItem("user") || '',
    },
    mutations: {
        auth_request(state) {
            state.isLoading = 1;
        },
        auth_success(state, token, user) {
            state.isLoading = 0;
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.isLoading = 0;
        },
        logout_request(state) {
            state.isLoading = 1;
        },
        logout_success(state) {
            state.isLoading = 0;
            state.token = "";
            state.user = "";
        },
        logout_error(state) {
            state.isLoading = 0;
        },
        addToCart(state) {
           state.cart++ ;
        },
    },
    actions: {
        login({ commit }, user_credentials) {
            return new Promise((resolve, reject) => {
              commit("auth_request");
              axios
                .post(
                    DDM_URL+"auth/login",
                  user_credentials
                )
                .then(resp => {
                  if (resp.data.access_token) {   
                    console.log('vuex',resp)
                    const token = resp.data.access_token;
                    const user = resp.data.user;
                    const client = resp.data.client;
                    if (resp.data.client) {
                      
                      localStorage.setItem("token", token);
                      localStorage.setItem("user", JSON.stringify(user));
                      localStorage.setItem("client", JSON.stringify(client));
                      commit("auth_success");
                      resolve(resp);
                    }
                    
                    
                  } else {
                    commit("auth_error");
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    localStorage.removeItem("client");
                    reject(resp);
                  }
                })
                .catch(err => {
                  commit("auth_error");
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  localStorage.removeItem("client");
                  reject(err);
                });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                
                commit("logout_request");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("client");
                localStorage.removeItem("cartShooping");
                commit("logout_success");
                const token = localStorage.getItem("token");
                resolve();
                if (token === 1) {
                  reject()
                }
            });
          },
        addCart({ commit },shopping) {
        return new Promise((resolve, reject) => {
            
            localStorage.setItem("cartShooping",shopping);
            commit("cart");
        });
        },
    }
})