<template>
    <div>
       
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="3" style="background-image: url(/images/annales.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2 class="text-uppercase">{{examenTitle}}</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active text-uppercase" aria-current="page">{{examenTitle}}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="courses-part" class="pt-120 pb-120 gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="courses-top-search">
                            <ul class="nav float-left" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="active" id="courses-grid-tab" data-toggle="tab" href="#courses-grid" role="tab" aria-controls="courses-grid" aria-selected="true"><i class="fa fa-th-large"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a id="courses-list-tab" data-toggle="tab" href="#courses-list" role="tab" aria-controls="courses-list" aria-selected="false"><i class="fa fa-th-list"></i></a>
                                </li>
                            </ul>
                            <div class="courses-search float-right">
                                <form action="#">
                                    <input type="text" placeholder="Rechercher un annale">
                                    <button type="button"><i class="fa fa-search"></i></button>
                                </form>
                            </div> &nbsp; &nbsp;
                            <div class=" float-right">
                                <select class="form-control float-right" v-model="sortChoose" @change="getSubjectId(sortChoose)">
                                    <option :value="0">Trier par matiere </option>
                                    <option v-for="(subject,index) in subjects" :key="index" :value="subject.id"  class="text-capitalize"> {{subject.label}} </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="examens.length > 0" class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="courses-grid" role="tabpanel" aria-labelledby="courses-grid-tab">
                        <div class="row">
                            <div class="col-lg-4 col-md-6" v-for="(examen,index) in examens" :key="index">
                                <div class="single-course mt-30">
                                    <div class="thum">
                                        <div class="image">
                                            <img src="https://via.placeholder.com/1800x1253?text=devoirdemaison.com" alt="Course">
                                        </div>
                                        <!-- <div class="price" v-if="examen.free == 1" >
                                            <span style="background-color: #00F731; color:black">Gratuit</span>
                                        </div>
                                        <div v-if="examen.free == 0" class="price">
                                            <span>Payant</span>
                                        </div> -->
                                    </div>
                                    <div class="cont">
                                        <ul>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                        </ul>
                                        <span>(50 consultations)</span> <br>
                                        <router-link :to="'/sujets-de-devoir/'+examen.id"><h4> {{examen.label}} </h4> <span> Année: {{examen.year}}</span></router-link>
                                        <p>({{examen.subjects.label}} )</p>
                                        <div class="course-teacher">
                                            <div class="name">
                                                <router-link :to="'/sujets-de-devoir/'+examen.id"><h6>  </h6></router-link>
                                            </div>
                                            <div class="admin float-left">
                                                <ul>
                                                    <li><a href="#"><i class="fa fa-user"></i><span>31</span></a></li>
                                                    <li><a href="#"><i class="fa fa-heart"></i><span>10</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="tab-pane fade" v-for="(examen,index) in examens" :key="index" id="courses-list" role="tabpanel" aria-labelledby="courses-list-tab">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="single-course mt-30">
                                    <div class="row no-gutters">
                                        <div class="col-md-6">
                                            <div class="thum">
                                                <div class="image">
                                                    <img src="/images/course/course-shape.png" alt="Course">
                                                </div>
                                                <div class="price" v-if="examen.free">
                                                    <span>Gratuit</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="cont">
                                                
                                                <span>({{examen.subject.label}})</span> 
                                                <router-link :to="'/annale-details/'+examen.id"><h4> {{examen.label}} </h4></router-link>
                                                <div class="course-teacher">
                                                    
                                                    <div class="name">
                                                        <router-link :to="'/sujets-de-devoir/'+examen.id"><h6> {{examen.level}} </h6></router-link>
                                                    </div>
                                                    <div class="admin">
                                                        <ul>
                                                            <li><a href="#"><i class="fa fa-user"></i><span>31</span></a></li>
                                                            <li><a href="#"><i class="fa fa-heart"></i><span>10</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h3 class="alert-danger text-center m-4 p-4">
                        Pas de sujet pour cette matière
                    </h3>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="courses-pagination mt-50">
                            <ul class="pagination justify-content-center">
                                <li class="page-item">
                                    <a href="#" aria-label="Previous">
                                        <i class="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                <li class="page-item"><a class="active" href="#">1</a></li>
                                <li class="page-item"><a href="#">2</a></li>
                                <li class="page-item"><a href="#">3</a></li>
                                <li class="page-item">
                                    <a href="#" aria-label="Next">
                                        <i class="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav> 
                    </div>
                </div> 
            </div>
        </section>
    </div>
</template>
<script>
import { DDM_URL,DDM } from '@/config'
import axios from "axios"
export default {
  
  data () {
    return {
      examens: {},
      DDM:DDM,
      isActive:null,
      subjects:[],
      sortChoose:0,
      examenTitle:null
    }
  },
  created () {
      this.getSubjects()
      this.getExamen()
      
  },
  methods: {
    showCollapse (index) { 
      this.isActive = index
    },
    getExamen () { 
        let app = this;
        app.isLoading = true;
        let id = this.$route.params.idAnnale
        axios.get(DDM_URL+'sujets-examen/'+id)
        .then((response) => {
            console.log('sujet by examen:',response.data)
            app.examens = response.data
            app.examenTitle = app.examens[0].subjects.examen.label
            this.isLoading = false;
        })
        .catch((   ) => {
            this.isLoading = false;
        });
    },
    getSubjects () { 
        let app = this;
        app.isLoading = true;
        let id = this.$route.params.idAnnale
        axios.get(DDM_URL+'subject-by-level/'+id)
        .then((response) => {
            console.log('matiere:',response.data)
            app.subjects = response.data
            this.isLoading = false;
        })
        .catch((   ) => {
            this.isLoading = false;
        });
    },
    getSubjectId (id) { 
        if (id == 0) {
            this.getExamen()
        } else {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'sujet-by-subject/'+id)
            .then((response) => {
                console.log('sujet by subject:',response.data)
                app.examens = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        }
        
    },

  },
    
}
</script>