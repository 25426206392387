<template>
    <div>
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="3" style="background-image: url(images/boutique.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2 class="text-uppercase">Boutique</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active text-uppercase" aria-current="page">Boutique</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="event-page" class="pt-90 pb-120 gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="shop-top-search">
                            <div class="shop-bar">
                                <ul class="nav" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="active" id="shop-grid-tab" data-toggle="tab" href="#shop-grid" role="tab" aria-controls="shop-grid" aria-selected="true"><i class="fa fa-th-large"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="float-right">
                                <select class="form-control"  v-model="sortChoose" @change="getShortMarketType(sortChoose)">
                                    <option :value="0">Trier par </option>
                                    <option v-for="(type,index) in productTypes" :key="index"> {{type.label}} </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-8" v-for="(product,index) in products" :key="index">
                    <div class="single-publication mt-30 text-center">
                        <div class="image">
                            <img :src="DDM+product.images[0]" alt="Publication">
                            <div class="add-cart">
                                <ul>
                                    <li><a href="#"><i class="fa fa-shopping-cart"></i></a></li>
                                    <li><a href="#"><i class="fa fa-heart-o"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content pt-20">
                            <h5 class="book-title"><a href="#">{{product.label}} </a></h5>
                            <p class=""><span></span> {{product.description}} </p>
                            <div class="price-btn d-flex align-items-center justify-content-between">
                                <div class="price pt-20">
                                    <span class="normal-price">{{product.price}} Fcfa</span>
                                </div>
                                <div class="button pt-10">
                                    <router-link :to="'/boutique-details/'+product.id" class="main-btn" style="color:white"><i class="fa fa-eye"></i> Détails</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card-footer text-center border-0  pb-0 pt-3" style="background-color:transparent;">
                            <jw-pagination :pageSize="6" :items="exampleItems" @changePage="onChangePage" :labels="customLabels"></jw-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};
import axios from "axios";
import { DDM_URL,DDM} from "@/config";
export default {
  data () {
    return {
        products:[],
        DDM:DDM,
        exampleItems:[],
        pageOfItems: [],
        customLabels,
        productTypes:[],
        sortChoose:0,
    }
  },
  created () {
      this.getProducts()
      this.getShortMarketTypes()
  },
  methods: {
        onChangePage(pageOfItems) {
            // update page of items
            this.products = pageOfItems;
        },
        getProducts: function() {
            let app = this
            this.loading = true
            axios.get(DDM_URL+"short-markets")
            .then((response) => {
                
                app.products = response.data
                response.data.forEach(element => {
                    var test = element.photos.split(';')
                    test.pop()
                    element.images= test;
                });
                this.exampleItems=response.data
                this.loading = false
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        getShortMarketTypes: function(){
            let app = this
            this.loading = true
            axios.get(DDM_URL+"short-market-types")
            .then((response) => {
                app.productTypes = response.data
                console.log('type',app.productTypes)
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        getShortMarketType: function(id) {
            if (id == 0) {
                this.getProducts()
            }else{
                let app = this
                this.loading = true
                axios.get(DDM_URL+"short-market-by-type/"+id)
                .then((response) => {
                    response.data.forEach(element => {
                        var test = element.photos.split(';')
                        test.pop()
                        element.images= test;
                    });
                    app.products = response.data
                    console.log(response.data)
                    this.exampleItems=response.data
                    this.loading = false
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false
                });
            }
            
        },
  },
    
}
</script>