<template>
    <div>
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="3" style="background-image: url(/images/annales.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2 class="text-uppercase">épreuves d'examens</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active text-uppercase" aria-current="page">épreuves d'examens</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="courses-part" class="pt-120 pb-120 gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="courses-top-search">
                            <ul class="nav float-left" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="active" id="courses-grid-tab" data-toggle="tab" href="#courses-grid" role="tab" aria-controls="courses-grid" aria-selected="true"><i class="fa fa-th-large"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a id="courses-list-tab" data-toggle="tab" href="#courses-list" role="tab" aria-controls="courses-list" aria-selected="false"><i class="fa fa-th-list"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="courses-grid" role="tabpanel" aria-labelledby="courses-grid-tab">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-4 col-md-6" v-for="(examen,index) in examens" :key="index">
                                <div class="single-course mt-30">
                                    <div class="thum">
                                        <div class="image">
                                            <img src="https://via.placeholder.com/1800x1253?text=devoirdemaison.com" alt="Course">
                                        </div>
                                        
                                    </div>
                                    <div class="cont">
                                        <ul>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                        </ul>
                                        <span>({{examen.sujets.length}} sujets)</span>
                                        <router-link :to="'/annale-details/'+examen.id"><h4> {{examen.label}} / {{examen.country.name}} </h4></router-link>
                                        <div class="course-teacher">
                                            
                                            <div class="name">
                                                <router-link :to="'/annale-details/'+examen.id"><h6> {{examen.level}} </h6></router-link>
                                            </div>
                                            <div class="admin">
                                                <ul>
                                                    <li><a href="#"><i class="fa fa-user"></i><span>31</span></a></li>
                                                    <li><a href="#"><i class="fa fa-heart"></i><span>10</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" v-for="(examen,index) in examens" :key="index" id="courses-list" role="tabpanel" aria-labelledby="courses-list-tab">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="single-course mt-30">
                                    <div class="row no-gutters">
                                        <div class="col-md-6">
                                            <div class="thum">
                                                <div class="image">
                                                    <img src="https://via.placeholder.com/1800x1253?text=devoirdemaison.com" alt="Course">
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="cont">
                                                <ul>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                                <span>({{examen.sujets.length}}  sujets)</span> <br>
                                                <router-link :to="'/annale-details/'+examen.id"><h4> {{examen.label}} / {{examen.country.name}} </h4></router-link>
                                                <div class="course-teacher">
                                                    
                                                    <div class="name">
                                                        <router-link :to="'/annale-details/'+examen.id"><h6> {{examen.level}} </h6></router-link>
                                                    </div>
                                                    <div class="admin">
                                                        <ul>
                                                            <li><a href="#"><i class="fa fa-user"></i><span>31</span></a></li>
                                                            <li><a href="#"><i class="fa fa-heart"></i><span>10</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { DDM_URL } from '@/config'
import axios from "axios"
export default {
  methods: {
    getExamens () { 
        let app = this;
        app.isLoading = true;
        axios.get(DDM_URL+'examens')
        .then((response) => {console.log('schools:',response.data)
            app.examens = response.data
            this.isLoading = false;
        })
        .catch((   ) => {
            this.isLoading = false;
        });
    },

  },
    mounted: function(){
        this.getExamens()
    },
  data () {
    return {
      examens: []
    }
  },
    
}
</script>