<template>
    <div>
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(/images/page-banner-1.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2>{{examen.label}}</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item"><a href="#">Sujet de Devoir</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{examen.label}}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="event-single" class="pt-120 pb-120 gray-bg">
            <div class="container">
                <div class="events-area">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="events-left">
                                <h3>{{examen.label}}</h3>
                                <img src="/images/event/single-event/se-1.jpg" alt="Event">
                                <div class="curriculum-cont">
                                    <div class="float-left">
                                        <a :href="DDM+examen.document" > 
                                            <a :href="DDM+examen.document" download="mathematique" target="_bank">{{examen.label}} </a>
                                        </a>
                                    </div>
                                    <div class="float-right" v-if="examen.free == 1 ">
                                        <a :href="DDM+examen.corrected" > 
                                            <a :href="DDM+examen.corrected" download="mathematique" target="_bank"> Corrigé </a>
                                        </a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="course-features mt-30">
                                    <h4>Course Features </h4>
                                        <ul>
                                            <li><i class="fa fa-clock-o"></i>Duration : <span>10 Hours</span></li>
                                            <li><i class="fa fa-clone"></i>Leactures : <span>09</span></li>
                                            <li><i class="fa fa-beer"></i>Quizzes :  <span>05</span></li>
                                            <li><i class="fa fa-user-o"></i>Students :  <span>100</span></li>
                                        </ul>
                                        <div class="price-button pt-10" v-if="examen.free == '0'">
                                            <span>Prix : <b> {{examen.price}} </b></span>
                                            <a href="#" class="main-btn" style="color:white">Acheter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { DDM_URL,DDM } from '@/config'
import axios from "axios"
export default {
  
  data () {
    return {
      examen: {},
      DDM:DDM,
      isActive:null
    }
  },
  created () {
      this.getExamen()
  },
  methods: {
    showCollapse (index) { 
      this.isActive = index
    },
    getExamen () { 
        let app = this;
        app.isLoading = true;
        let id = this.$route.params.id
        axios.get(DDM_URL+'evaluation/'+id)
        .then((response) => {console.log('examen single:',response.data)
            app.examen = response.data
            this.isLoading = false;
        })
        .catch((   ) => {
            this.isLoading = false;
        });
    },

  },
    
}
</script>