<template>
    <div>
        <section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(/images/page-banner-1.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2>Login</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">login</li>
                                </ol>
                            </nav>
                        </div>  <!-- page banner cont -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
        </section>
        <section class="signup pt-105 pb-120 gray-bg">
            <div class="container">
                <div class="col-md-8 offset-md-2">
                    <div class="signup-content">
                        <form method="POST" id="signup-form" class="signup-form" @submit.prevent="login">
                            <h2 class="form-title pb-20">se connecter</h2>
                            
                            <div class="form-group">
                                <input type="email" v-model="user.email" class="form-input" name="email" id="email" placeholder="Ton email"/>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-input" v-model="user.password" name="password" id="password" placeholder="mot de passe"/>
                                <span toggle="#password" class="zmdi zmdi-eye field-icon toggle-password"></span>
                            </div>
                            <div class="form-group">
                                <input type="submit" name="submit" id="submit" class="main-btn register-submit" value="Connexion"/>
                            </div>
                        </form>
                        <p class="loginhere">
                            vous n'avez pas de compte ? <a href="/register" class="loginhere-link">Creer un compte</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {
            user:{
            }
        }
    },
    methods: {
        login() {
                this.isLoading = true;
                this.$store.dispatch("login", this.user)
                .then(() => {          
                   
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                
                });
        },
    },
  
    
}
</script>