<template>
    <div>
        <section id="page-banner" class="pt-105 pb-130 bg_cover" data-overlay="3" style="background-image: url(/images/par_terre.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-banner-cont">
                            <h2 class="text-uppercase">Par Terre</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                    <li class="breadcrumb-item active text-uppercase" aria-current="page">Par terre</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
        <section id="shop-page" class="pt-120 pb-120 gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="shop-top-search">
                            <div class="shop-bar">
                                <ul class="nav" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="active" id="shop-grid-tab" data-toggle="tab" href="#shop-grid" role="tab" aria-controls="shop-grid" aria-selected="true"><i class="fa fa-th-large"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="shop-list-tab" data-toggle="tab" href="#shop-list" role="tab" aria-controls="shop-list" aria-selected="false"><i class="fa fa-th-list"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div class=" float-right">
                                <select class="form-control" v-model="sortChoose" @change="chooseType(sortChoose)" id="" name="">
                                    <option :value="0">Tout</option>
                                    <option :value="1">Livre</option>
                                    <option :value="2">Romans</option>
                                    <option :value="3">Oeuvres théatrales</option>
                                    <option :value="4">Cahiers d'activités</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="shop-grid" role="tabpanel" aria-labelledby="shop-grid-tab">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-md-6 col-sm-8" v-for="(product,index) in products" :key="index">
                                <div class="single-publication mt-30 text-center">
                                    <div class="image">
                                        <img :src="DDM+product.photo[0]" alt="Publication">
                                        <div class="add-cart">
                                            <ul>
                                                <li><a href="#"><i class="fa fa-shopping-cart"></i></a></li>
                                                <li><a href="#"><i class="fa fa-heart-o"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="content pt-20">
                                        <h5 class="book-title"><a href="#">{{product.label}} </a></h5>
                                        <div class="description">
                                            <p>Lorem Ipsum has been the industry's standard dummy text</p>
                                        </div>
                                        <div class="price-btn d-flex align-items-center justify-content-between">
                                            <div class="price pt-20">
                                                <!-- <span class="discount-price">$250</span> -->
                                                <span class="normal-price">{{product.price}} Fcfa</span>
                                            </div>
                                            <div class="button pt-10">
                                                <router-link :to="'/par-terre-details/'+product.id" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Acheter</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="shop-list" role="tabpanel" aria-labelledby="shop-list-tab">
                        <div class="row">
                            <div class="col-lg-6" v-for="(product,index) in products" :key="index">
                                <div class="single-publication mt-30">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="image">
                                                <img :src="DDM+product.photo[0]" alt="Publication">
                                                <div class="add-cart">
                                                    <ul>
                                                        <li><a href="#"><i class="fa fa-shopping-cart"></i></a></li>
                                                        <li><a href="#"><i class="fa fa-heart-o"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="content pt-20">
                                                <h5 class="book-title"><a href="#">{{product.label}} </a></h5>
                                                <div class="description">
                                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                                </div>
                                                <div class="price-btn d-flex align-items-center justify-content-between">
                                                    <div class="price pt-20">
                                                        <span class="normal-price">{{product.price}} Fcfa</span>
                                                    </div>
                                                    <div class="button pt-10">
                                                        <router-link :to="'/par-terre-details/'+product.id" class="main-btn" style="color:white"><i class="fa fa-cart-plus"></i> Acheter</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card-footer text-center border-0  pb-0 pt-3" style="background-color:transparent;">
                            <jw-pagination :pageSize="8" :items="exampleItems" @changePage="onChangePage" :labels="customLabels"></jw-pagination>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    </div>
</template>
<script>
import { DDM_URL,DDM } from '@/config'
import axios from "axios"

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};

export default {
    data : function() {
        return {
            products:[],
            exampleItems:[],
            pageOfItems: [],
            sortChoose:0,
            customLabels,
            DDM:DDM
            
        }
    },
    mounted: function(){
        this.getShopping()
    },
    methods:{
        onChangePage(pageOfItems) {
            // update page of items
            this.products = pageOfItems;
        },
        getShopping: function() {
            let app = this;
            app.isLoading = true;
            axios.get(DDM_URL+'products')
            .then((response) => {
                    
                    response.data.forEach(element => {
                        var tof = element.photo.split(';')
                        tof.pop()
                        element.photo = tof
                    });
                    console.log('shopping:',response.data)
                app.products = response.data
                app.exampleItems = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
        chooseType(choose) {
            if (choose == 0) {
                this.getShopping()
            }else{
                let app = this;
                app.isLoading = true;
                console.log(choose)
                axios.post(DDM_URL+'products-type',{
                    id:choose
                })
                .then((response) => {console.log('type:',response.data)
                    app.products = response.data
                    app.exampleItems = [...response.data].map((product,index) => ({ id: product.id, label: product.label,level:product.level,price:product.price }));
                    this.isLoading = false;
                })
                .catch((   ) => {
                    this.isLoading = false;
                });
            }
            
        },
    }
}
</script>