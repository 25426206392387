<template>
    <div>
        <div class="preloader">
            <div class="loader rubix-cube">
                <div class="layer layer-1"></div>
                <div class="layer layer-2"></div>
                <div class="layer layer-3 color-1"></div>
                <div class="layer layer-4"></div>
                <div class="layer layer-5"></div>
                <div class="layer layer-6"></div>
                <div class="layer layer-7"></div>
                <div class="layer layer-8"></div>
            </div>
        </div>
        <Header></Header>
            <div class="search-box">
                <div class="search-form">
                    <div class="closebtn">
                        <span></span>
                        <span></span>
                    </div>
                    <form action="#">
                        <input type="text" placeholder="Search by keyword">
                        <button><i class="fa fa-search"></i></button>
                    </form>
                </div> <!-- search form -->
            </div>
            <router-view/>
        <Footer></Footer>
    </div>
</template>
<script>
import Header  from "@/components/header.vue"
import Footer  from "@/components/footer.vue"
export default {
    components:{
        Header,
        Footer
    },
}
</script>